import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as PhoneSvg } from "../../assets/Phone.svg";
import { ReactComponent as ShopSvg } from "../../assets/Shop.svg";
import { setShopItems } from "../../store/AppSlice";
import { useDispatch, useSelector } from "react-redux";
import products_data from "../../screens/ProductPage/products_data";

export default function ProductCard({
  image,
  title,
  price,
  showPrice = true,
  containerStyle = {},
  catalogName,
}) {
  const dispatch = useDispatch();
  const shopItems = useSelector(({ app }) => app.shopItems);
  return (
    <div
      style={{
        width: "240px",
        height: "360px",
        backgroundColor: "#fff",
        boxShadow: "0px 4px 10px 3px rgba(81, 81, 81, 0.07)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "10px 10px",
        cursor: "pointer",
        textDecoration: "none",
        ...containerStyle,
      }}
    >
      <Link
        to={`/productPage/${
          catalogName === "vibropress" ? "Вибропрессованная-" : ""
        }${title}`}
        state={{ title: title, catalogName: catalogName }}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
          textDecoration: "none",
        }}
      >
        <img
          src={image}
          style={{
            width: "220px",
            height: "220px",
            objectFit: "contain",
            margin: "auto",
          }}
          alt={"Тротуарная плитка " + title}
        />
        <h3
          style={{
            fontFamily: "GothamPro",
            fontWeight: "300",
            fontSize: "20px",
            textAlign: "center",
            color: "#121212",
          }}
        >
          {title}
        </h3>
        {showPrice && (
          <p
            style={{
              fontFamily: "GothamPro",
              fontWeight: "300",
              fontSize: "16px",
              lineHeight: "15px",
              textAlign: "left",
              color: "#000000",
              marginLeft: "5px",
            }}
          >
            {price}руб/м2
          </p>
        )}
      </Link>
      <div
        style={{
          width: "100%",
          height: "58px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <a href="tel:89162335987">
          <PhoneSvg />
        </a>
        <div
          style={{
            height: "25px",
            width: "1px",
            backgroundColor: "rgba(18, 18, 18, 0.2)",
          }}
        />
        <a href="tel:89162335987">
          <ShopSvg
          // onClick={() => {
          //   const data = {
          //     title: title,
          //     color: products_data.colors[0].title,
          //     size: products_data[
          //       `${
          //         catalogName === "vibropress" ? "Вибропрессованная-" : ""
          //       }${title}`
          //     ].sizes[0],
          //     price: "350",
          //     image: image,
          //     count: 1,
          //   };
          //   dispatch(setShopItems([...shopItems, data]));
          // }}
          />
        </a>
      </div>
    </div>
  );
}
