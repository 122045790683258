import React, { useState } from "react";
import styles from "./style.module.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { useLocation, useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import products_data from "./products_data";
import Button from "../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { setShopItems } from "../../store/AppSlice";
import catalogs_data from "../CatalogsList/catalogs_data";
import useScrollToTop from "../../helpers/useScrollToTop";
export default function ProductPage() {
  const { productName } = useParams();
  const [chooseSize, setChooseSize] = useState(
    products_data[productName]?.sizes?.[0]
  );
  const [chooseColor, setChooseColor] = useState(
    products_data.colors?.[0].title
  );

  const location = useLocation();
  const { title, catalogName } = location.state;
  const shopItems = useSelector(({ app }) => app.shopItems);
  const dispatch = useDispatch();

  useScrollToTop();

  return (
    <div>
      <Header />
      <div className={styles.main}>
        <div className={styles.catalogContainer}>
          <div className={styles.sliderContainer}>
            <Carousel
              autoPlay
              infiniteLoop
              interval={6000}
              showArrows={false}
              showStatus={false}
              // showThumbs={false}
              transitionTime={2000}
              topOnHover={false}
              showIndicators={false}
            >
              {products_data[productName].images.map((img, index) => (
                <img
                  key={index}
                  className="img"
                  src={img}
                  style={{ height: "auto", widows: "100%" }}
                  alt={
                    (catalogName === "vibrolitya"
                      ? "Вибролитая тротуарная плитка "
                      : catalogName === "vibropress"
                      ? "Вибропрессованная тротуарная плитка "
                      : "") + title
                  }
                />
              ))}
            </Carousel>
          </div>
          <div className={styles.rightContainer}>
            <h2 className={styles.title}>{title}</h2>
            <h2 className={styles.price}>
              {
                typeof products_data[productName].price == "string" ||
                typeof products_data[productName].price == "number"
                  ? products_data[productName].price
                  : // : productName == "Бордюр тротуарный"
                  // ? chooseSize === "500х200х60"
                  //   ? "70"
                  //   : "80"
                  products_data[productName].price
                  ? chooseColor === "Серый"
                    ? products_data[productName].price?.seri
                    : products_data[productName].price?.cvetnoi
                  : chooseSize?.includes("300x300")
                  ? chooseColor === "Серый"
                    ? "450"
                    : ""
                  : chooseSize?.includes("350x350")
                  ? chooseColor === "Серый"
                    ? "650"
                    : ""
                  : chooseSize?.includes("400x400")
                  ? chooseColor === "Серый"
                    ? "650"
                    : ""
                  : chooseSize?.includes("500x500")
                  ? chooseColor === "Серый"
                    ? "650"
                    : ""
                  : ""
                // chooseColor === "Серый"
                // ? "500"
                // : "600"
              }
              ₽
            </h2>
            {products_data?.[productName]?.sizes?.length ? (
              <div className={styles.sizeContainer}>
                <span className={styles.sizeTitle}>Выбор размера</span>
                {products_data?.[productName]?.sizes?.map((size, index) => (
                  <div
                    key={index}
                    onClick={() => setChooseSize(size)}
                    className={styles.sizeRow}
                  >
                    <div className={styles.selectDot}>
                      {chooseSize === size && (
                        <div className={styles.activeDot} />
                      )}
                    </div>
                    <span className={styles.size}>{size}</span>
                  </div>
                ))}
              </div>
            ) : null}

            {products_data[productName]?.colors !== false ? (
              <div className={styles.colorContainer}>
                <span className={styles.colorTitle}>Выбор цвета</span>
                {products_data.colors.map((color, index) => (
                  <div
                    key={index}
                    onClick={() => setChooseColor(color.title)}
                    className={styles.colorRow}
                  >
                    <div className={styles.selectDot}>
                      {chooseColor === color.title && (
                        <div className={styles.activeDot} />
                      )}
                    </div>
                    <img
                      className={styles.colorImg}
                      src={color.img}
                      alt={color.title}
                    />
                    <span className={styles.color}>{color.title}</span>
                  </div>
                ))}
              </div>
            ) : null}

            <Button
              text="Купить"
              href="tel:89162335987"
              // onClick={() => {
              //   const data = {
              //     title: title,
              //     color: chooseColor,
              //     size: chooseSize,
              //     price: "350",
              //     image: catalogs_data[catalogName]?.products?.find(
              //       (item) => item.title === title
              //     )?.image,
              //     count: 1,
              //   };
              //   dispatch(setShopItems([...shopItems, data]));
              // }}
              textStyle={{ fontSize: "16px" }}
              style={{ padding: "15px 30px", marginTop: "30px" }}
            />
            <p className={styles.description}>
              {products_data[productName].description}
            </p>
          </div>
        </div>
        <div className={styles.characterizations}>
          <h2 className={styles.characterizationsTitle}>Характеристики</h2>
          <div className={styles.characterizationsMain}>
            <div className={styles.characterizationsPart}>
              <span className={styles.characterizationsKey}>Класс бетона</span>
              <span className={styles.characterizationsValue}>В30</span>
            </div>
            <div className={styles.characterizationsLine} />
            <div className={styles.characterizationsPart}>
              <span className={styles.characterizationsKey}>
                Марка морозостойкости
              </span>
              <span className={styles.characterizationsValue}>F 200</span>
            </div>

            <div
              className={`${styles.characterizationsLine} ${styles.hideLine2}`}
            />
            <div className={styles.characterizationsPart}>
              <span className={styles.characterizationsKey}>
                Истираемость бетона
              </span>
              <span className={styles.characterizationsValue}>
                {"< 0,7 г/см"}
                <sup>
                  <small>2</small>
                </sup>
              </span>
            </div>
            <div
              className={`${styles.characterizationsLine} ${styles.hideLine}`}
            />
            <div className={styles.characterizationsPart}>
              <span className={styles.characterizationsKey}>
                Истираемость бетона
              </span>
              <span className={styles.characterizationsValue}>{"< 6%"}</span>
            </div>
            <div
              className={`${styles.characterizationsLine} ${styles.hideLine2}`}
            />
            <div className={styles.characterizationsPart}>
              <span className={styles.characterizationsKey}>
                Водопоглощение
              </span>
              <span className={styles.characterizationsValue}>Btb=3,6</span>
            </div>
            <div className={styles.characterizationsLine} />
            <div className={styles.characterizationsPart}>
              <span className={styles.characterizationsKey}>ГОСТ</span>
              <span className={styles.characterizationsValue}>17608-91</span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
