import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAfterSendMail, setShowCallBackModal } from "../../store/AppSlice";
// import ModalItem from "./ModalItem";
import styles from "./styles.module.css";
import Button from "../Button";
import { sendMail } from "../../api/api";

export default function Index() {
  const [tel, setTel] = useState("");
  const { showCallBackModal } = useSelector(({ app }) => app);
  const disptach = useDispatch();

  if (showCallBackModal) {
    return (
      <div
        className={styles.container}
        onClick={(e) => {
          disptach(setShowCallBackModal(false));
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.main}
        >
          <p className={styles.title}>
            Оставьте ваш номер телефона и мы сами вам перезвоним!
          </p>

          <input
            className={styles.modalInput}
            placeholder="+7(999)999-9999"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
          />

          <Button
            onClick={() => {
              sendMail({ type: "callBack", tel: tel }).then((successfully) => {
                disptach(
                  setAfterSendMail(successfully ? "successfully" : "failed")
                );
              });
              setTel("");
            }}
            text="Заказать звонок"
            fill
            style={{ display: "inline-flex", alignSelf: "center" }}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
}
