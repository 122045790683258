import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setAfterSendMail } from "../../store/AppSlice";

export default function Index() {
  const afterSendMail = useSelector(({ app }) => app.afterSendMail);
  const dispatch = useDispatch();
  useEffect(() => {
    if (afterSendMail) {
      setTimeout(() => {
        dispatch(setAfterSendMail(null));
      }, 3000);
    }
  }, [afterSendMail]);
  return (
    <div
      className={styles.main}
      style={{
        top: afterSendMail ? "10px" : "-100px",
        // display: afterSendMail ? "flex" : "none",
      }}
    >
      <img
        className={styles.img}
        src={
          afterSendMail === "successfully"
            ? require("./assets/check.png")
            : require("./assets/remove.png")
        }
      />
      <span className={styles.text}>
        {afterSendMail === "successfully"
          ? "Отправлено"
          : "Что-то прошло не так"}
      </span>
    </div>
  );
}
