import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.css";
import { ReactComponent as LongArrowSvg } from "../../../../assets/LongArrow.svg";

const SlideItem = ({ position, image, title, description, onClickMore }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: position !== "left" ? "row" : "row-reverse",
        justifyContent: "space-between",
        minHeight: "377px",
        width: "95%",
        margin: "0 auto",
      }}
      className={styles.main}
    >
      <div className={styles.part}>
        <img
          src={image}
          alt={title}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </div>
      <div className={styles.part}>
        <h2 className={styles.title} style={{ textAlign: position }}>
          {title}
        </h2>
        <p
          className={styles.description}
          style={{
            textAlign: position,
          }}
        >
          {description}
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: position === "left" ? "flex-start" : "flex-end",
          }}
        >
          <div style={{}}>
            <Link className={styles.showMore} to={onClickMore}>
              {position === "right" && (
                <div
                  style={{
                    transform: "rotate(180deg)",
                    position: "relative",
                    top: "-3px",
                  }}
                >
                  <LongArrowSvg />
                </div>
              )}
              ПОДРОБНЕЕ {position === "left" && <LongArrowSvg />}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideItem;
