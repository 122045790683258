import React from "react";
import styles from "./style.module.css";
import { ReactComponent as Icon1 } from "./assets/icon1.svg";
import { ReactComponent as Icon2 } from "./assets/icon2.svg";
import { ReactComponent as Icon3 } from "./assets/icon3.svg";
import { ReactComponent as Icon4 } from "./assets/icon4.svg";
import { Slide } from "react-reveal";
import Title from "../../../components/Title";

export default function Index() {
  return (
    <div className={styles.container}>
      <Title
        text={"ЧЕМ ХОРОША НАША ПРОДУКЦИЯ РОСГОСТСТРОЙ"}
        // style={{ width: "700px" }}
      />
      <div className={styles.main}>
        {window.innerWidth < 990 ? (
          <div className={`${styles.second} ${styles.mainChild}`}>
            <img alt="img" src={require("./assets/section1.png")} />
          </div>
        ) : null}
        <div className={`${styles.first} ${styles.mainChild}`}>
          <Slide left>
            <div className={styles.mainChildContainer}>
              <Icon1 className={styles.mainChildIcon} />
              <div>
                <p className={styles.mainChildTitle}>Долговечная и надежная</p>
                <p className={styles.mainChildDescription}>
                  Срок эксплуатации до 50 лет Не трескается и не крошится
                </p>
              </div>
            </div>
          </Slide>
          <Slide left delay={100}>
            <div
              className={`${styles.mainChildContainer} ${styles.mainChildContainer2}`}
            >
              <Icon2 className={styles.mainChildIcon} />
              <div>
                <p className={styles.mainChildTitle}>
                  Красивая и разнообразная
                </p>
                <p className={styles.mainChildDescription}>
                  Сотни сочетаний цветов и форм Примеры выкладки на 2000 м2 в
                  нашем офисе
                </p>
              </div>
            </div>
          </Slide>
        </div>
        {window.innerWidth > 990 ? (
          <div className={`${styles.second} ${styles.mainChild}`}>
            <img alt="img" src={require("./assets/section1.png")} />
          </div>
        ) : null}

        <div className={`${styles.third} ${styles.mainChild}`}>
          <Slide right>
            <div className={styles.mainChildContainer}>
              <Icon3 className={styles.mainChildIcon} />
              <div>
                <p className={styles.mainChildTitle}>
                  Экологичная и безопасная
                </p>
                <p className={styles.mainChildDescription}>
                  Применяются безвредные компоненты Не скользкая при дожде и
                  гололедице
                </p>
              </div>
            </div>
          </Slide>
          <Slide right delay={100}>
            <div
              className={`${styles.mainChildContainer} ${styles.mainChildContainer4}`}
            >
              <Icon4 className={styles.mainChildIcon} />
              <div>
                <p className={styles.mainChildTitle}>Доставка 24 часа</p>
                <p className={styles.mainChildDescription}>
                  Надежная выверенная логистика Более 90 000 м2 всегда на складе
                </p>
              </div>
            </div>
          </Slide>
        </div>
      </div>
    </div>
  );
}
