import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./style.module.css";
import Title from "../../components/Title";
import CallBack from "../../components/CallBack/CallBack";
import useScrollToTop from "../../helpers/useScrollToTop";

export default function AboutUs() {
  useScrollToTop();
  return (
    <div>
      <Header />
      <div className={styles.main}>
        <Title text="О нас" />
        <h4 className={styles.subTitle}>
          ПОЧЕМУ ТРОТУАРНАЯ ПЛИТКА ОТ КОМПАНИИ РОСГОСТТРОЙ?
        </h4>
        <p className={styles.text}>
          Выпускаемая нами продукция отличается надежностью, которую
          обеспечивают базовые компоненты и специальная технология изготовления
          плитки. Прочность и привлекательный внешний вид ей обеспечивают
          цементы марки 500 ДО и гранитный отсев. В производстве наши
          специалисты пользуются методом вибролитья, который с точностью
          передает фактуру камня, поэтому внешне такую плитку не отличить от
          натуральных аналогов. Имея приемлемую цену, по качеству она не
          уступает кирпичу или гранитной брусчатке. Благодаря широкой палитре
          доступных в линейке моделей монтажники могут создавать очень
          интересные рисунки. Используется такая плитка для благоустройства
          тротуаров, аллей, фасадов. К преимуществам продукции относится и
          минимальное количество зазоров, которые есть между плитками после
          укладки, отсутствие вредных веществ в составе материала и
          долговечность — она не перегревается и не теряет своей формы при
          постоянном воздействии УФ. Наша компания предлагает тротуарную плитку
          различных размеров, расцветок и форм. Постоянные клиенты пользуются
          специальной системой скидок. Присоединяйтесь! Вот лишь несколько
          причин сделать свой выбор в пользу тротуарной плитки, купленной в
          нашей компании.
        </p>
        <CallBack />
      </div>
      <Footer />
    </div>
  );
}
