import React, { useState } from "react";
import Button from "../Button";
import styles from "./styles.module.css";
import { ReactComponent as TelegramSvg } from "./assets/Telegram.svg";
import { ReactComponent as ViberSvg } from "./assets/Viber.svg";
import { ReactComponent as WhatsAppSvg } from "./assets/Whatsapp.svg";
import { sendMail } from "../../api/api";
import { useDispatch } from "react-redux";
import { setAfterSendMail } from "../../store/AppSlice";

export default function CallBack() {
  const [name, setName] = useState("");
  const [time, setTime] = useState("");
  const [phone, setPhone] = useState("");

  const disptach = useDispatch();

  const handleFormSubmit = () => {
    sendMail({ type: "callBackForm", name, time, tel: phone }).then(
      (successfully) => {
        setName("");
        setTime("");
        setPhone("");
        disptach(setAfterSendMail(successfully ? "successfully" : "failed"));
      }
    );
  };

  return (
    <div>
      <h2 className={styles.title}>ЗАКАЗАТЬ ОБРАТНЫЙ ЗВОНОК</h2>
      <span className={styles.subTitle}>
        Закажите звонок и мы перезвоним в удобное для Вас время.
      </span>
      <div className={styles.row}>
        <form className={styles.form} onSubmit={(e) => console.log("e")}>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={`${styles.input} ${styles.longInput}`}
            placeholder="Имя*"
          />

          <input
            value={time}
            onChange={(e) => setTime(e.target.value)}
            className={styles.input}
            placeholder="Время*"
          />

          <input
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={`${styles.input} ${styles.longInput}`}
            placeholder="Телефон*"
            style={{ marginBottom: 0 }}
          />
          <div className={styles.btn}>
            <Button
              // onClick={handleFormSubmit}
              href="tel:89162335987"
              text="Заказать звонок"
              fill
              style={{
                width: "100%",
                height: "60px",
                padding: 0,
                justifyContent: "center",
                alignItems: "center",
                boxSizing: "border-box",
                margin: 0,
                // fontSize: "14px",
              }}
            />
          </div>
        </form>
        <div className={styles.shareContainer}>
          <span className={styles.shareText}>Поделиться:</span>
          {/* <FacebookSvg style={{ marginRight: "10px", cursor: "pointer" }} />
          <InstagramSvg style={{ marginRight: "10px", cursor: "pointer" }} /> */}
          <a href="https://t.me/RosGostStroy" target="_blank">
            <TelegramSvg style={{ marginRight: "10px", cursor: "pointer" }} />
          </a>
          <a href="https://wa.me/79160050707" target="_blank">
            <WhatsAppSvg style={{ marginRight: "10px", cursor: "pointer" }} />
          </a>
          <a href="viber://chat?number=+79160050707" target="_blank">
            <ViberSvg style={{ cursor: "pointer" }} />
          </a>

          {/* <VKSvg style={{ cursor: "pointer" }} /> */}
          <span className={`${styles.shareText} ${styles.shareText2}`}>
            Поделиться:
          </span>
        </div>
      </div>
    </div>
  );
}
