import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./style.module.css";
import Title from "../../components/Title";
import images from "./data";
import GalleryComponent from "./components/Gallery";
import useScrollToTop from "../../helpers/useScrollToTop";

export default function Portfolio() {
  useScrollToTop();
  return (
    <div>
      <Header />
      <div className={styles.main}>
        <Title text="Портфолио" />
        <div>
          <h3 className={styles.subTitle}>Посмотреть готовые работы</h3>
          <GalleryComponent images={images} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
