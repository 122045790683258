import React from "react";
import styles from "./header.module.css";
import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import Button from "../Button/index";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setShowCallBackModal } from "../../store/AppSlice";

export default function Footer() {
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <div className={`${styles.section} ${styles.logo}`}>
        <LogoSvg />
        <span className={styles.logoText}>РОСГОСТСТРОЙ</span>
        <div>
          <Button
            fill
            // onClick={() => dispatch(setShowCallBackModal(true))}
            href="tel:89162335987"
            text="Заказать звонок"
            style={{
              padding: window.innerWidth > 1200 ? "20px 40px" : "15px 20px",
            }}
          />
        </div>
      </div>
      <div className={styles.section}>
        <span className={styles.title}>КОНТАКТЫ</span>

        <p className={styles.footerText}>
          Адрес: Посёлок Фёдоровка, Дмитровский городской окру, Московская
          область
        </p>
        <p className={styles.footerText}>Эл. почта: info@rosgoststoy.ru</p>
        <div className={styles.telContainer}>
          <span className={styles.footerText}>Телефоны:</span>
          <div>
            <a className={styles.footerText} href="tel:89162335987">
              8 916 233 59 87
            </a>
            <a className={styles.footerText} href="tel:89850050506">
              8 985 005 05 06
            </a>
            <a className={styles.footerText} href="tel:89160050707">
              8 916 005 07 07
            </a>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <span className={styles.title}>КАТАЛОГ</span>
        <NavLink to="/catalog/vibrolitya" end className={styles.footerText}>
          Вибролитьевая плитка
        </NavLink>
        <NavLink to="/catalog/vibropress" end className={styles.footerText}>
          Вибропрессованная плитка
        </NavLink>
        <NavLink to="/catalog/bordyuri" end className={styles.footerText}>
          Бордюры
        </NavLink>
        <NavLink to="/catalog/vadastoki" end className={styles.footerText}>
          Водостоки
        </NavLink>
        <NavLink to="/catalog/krishi" end className={styles.footerText}>
          Крышки на столб/заборов
        </NavLink>
        <NavLink to="/catalog/latok" end className={styles.footerText}>
          Лоток водоотводный
        </NavLink>
      </div>
      <div className={styles.section}>
        <span className={styles.title}>УСЛУГИ</span>
        <NavLink to="/services/ukladka" end className={styles.footerText}>
          Укладка тротуарной плитки
          <div className={styles.line}></div>
        </NavLink>
        <NavLink to="/services/kovka" end className={styles.footerText}>
          Художественный ковка
          <div className={styles.line}></div>
        </NavLink>
        <NavLink to="/services/dostavka" end className={styles.footerText}>
          Доставка материалов
          <div className={styles.line}></div>
        </NavLink>
        <NavLink to="/services/drenaj" end className={styles.footerText}>
          Лоток водоотводный
          <div className={styles.line}></div>
        </NavLink>
        <NavLink
          to="/services/blagoustroistva"
          end
          className={styles.footerText}
        >
          Благоустройство территори
          <div className={styles.line}></div>
        </NavLink>
      </div>
      <div className={`${styles.section} ${styles.lastSection}`}>
        <Button
          href="tel:89162335987"
          // onClick={() => dispatch(setShowCallBackModal(true))}
          fill
          text="Заказать звонок"
        />
      </div>

      <a
        href="https://metrika.yandex.ru/stat/?id=93485219&amp;from=informer"
        target="_blank"
        rel="nofollow"
        className={styles.YandexMetrika}
      >
        <img
          src="https://informer.yandex.ru/informer/93485219/3_1_FFFFFFFF_EFEFEFFF_0_pageviews"
          style={{ width: "88px", height: "31px", border: 0 }}
          alt="Яндекс.Метрика"
          title="Яндекс.Метрика: данные за сегодня (просмотры, визиты и уникальные посетители)"
          class="ym-advanced-informer"
          data-cid="93485219"
          data-lang="ru"
        />
      </a>
    </div>
  );
}
