import React from "react";
import styles from "./style.module.css";
import Title from "../../../components/Title";
import ProductCard from "../../../components/ProductCard/ProductCard";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, Thumbs } from "swiper";
import { ReactComponent as ArrowSvg } from "../../../assets/Arrow.svg";
import top_sales_data from "./top_sales_data";
import { Link } from "react-router-dom";
import "swiper/css";

SwiperCore.use([Thumbs, Navigation, Pagination, Scrollbar]); //Navigation, Pagination, Scrollbar, A11y

export default function Index() {
  return (
    <div className={`${styles.container} top_sales`}>
      <Title text={"ТОП ПРОДАЖЫ"} style={{}} />
      <div className={styles.main}>
        <Swiper
          slidesPerView={window.innerWidth / 260}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {top_sales_data.map((item, index) => (
            <SwiperSlide>
              <ProductCard
                key={index}
                image={item.image}
                title={item.title}
                catalogName={item.catalogName}
                showPrice={false}
                containerStyle={{ width: "250px", marginBottom: "50px" }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <Link to="/catalog" className={styles.showMore}>
        <span>СМОТРЕТЬ ВСЕ</span>
        <ArrowSvg />
      </Link>
    </div>
  );
}
