import React from "react";
import styles from "./style.module.css";
import Button from "../../../components/Button/index";
import { ReactComponent as WhatsAppSvg } from "./assets/Whatsapp.svg";
import { ReactComponent as ViberSvg } from "./assets/Viber.svg";
import { ReactComponent as TelegramSvg } from "./assets/Telegram.svg";

import { useDispatch } from "react-redux";
import { setShowCallBackModal } from "../../../store/AppSlice";

export default function Index() {
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.firstRow}>
          <div className={styles.firstRowMain}>
            {/* <div className={styles.textRow}>
              <span className={styles.title}>Наш офис</span>
              <span className={styles.description}>
                деревня Удино Дмитровский городской округ, Московская область
              </span>
            </div> */}
            <div className={styles.textRow}>
              <span className={styles.title}>Наше производство</span>
              <span className={styles.description}>
                Посёлок Фёдоровка, Дмитровский городской окру, Московская
                область
              </span>
            </div>
            <div className={styles.textRow}>
              <span className={styles.title}>Контакты</span>
              <a
                className={styles.description}
                href="tel:89162335987"
                style={{ margin: 0 }}
              >
                8 916 233 59 87
              </a>
              <a className={styles.description} href="tel:89850050506">
                8 985 005 05 06
              </a>
            </div>
            <div className={styles.textRow}>
              <span className={styles.title}>Напишите нам</span>
              <a
                className={styles.description}
                href="mailto:info@rosgoststroy.ru "
              >
                info@rosgoststroy.ru
              </a>
            </div>
            <div className={styles.textRow}>
              <span className={styles.title}>Рабочее время</span>
              <span className={styles.description}>08:00 - 20:00</span>
            </div>
          </div>
          <iframe
            title="map"
            src="https://yandex.ru/map-widget/v1/?um=constructor%3A9943db11edd60ddc64de41e666cbac90fda059f14993657d8e9c4bf97d33747f&amp;source=constructor"
            style={{ width: "100%", height: "400px" }}
            frameBorder={0}
          ></iframe>
        </div>
        <div className={styles.secondRow}>
          <Button
            // onClick={() => dispatch(setShowCallBackModal(true))}
            href="tel:89162335987"
            fill
            text="Заказать звонок"
          />
          <div className={styles.shareContainer}>
            <span className={styles.shareText}>Поделиться:</span>

            <a href="https://t.me/RosGostStroy" target="_blank">
              <TelegramSvg className={styles.shareItem} />
            </a>
            <a href="https://wa.me/79160050707" target="_blank">
              <WhatsAppSvg className={styles.shareItem} />
            </a>
            <a href="viber://chat?number=+79160050707" target="_blank">
              <ViberSvg className={styles.shareItem} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
