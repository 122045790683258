import React from "react";
import styles from "./header.module.css";

import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import { NavLink } from "react-router-dom";
import HeaderMobile from "../HeaderMobile";

export default function Header() {
  if (window.innerWidth < 990) {
    return <HeaderMobile />;
  }
  return (
    <div className={styles.container} style={{ width: window.innerWidth }}>
      <NavLink to="/" className={styles.logo}>
        <LogoSvg />
        <span className={styles.logoText}>РОСГОСТСТРОЙ</span>
      </NavLink>
      <nav>
        <ul className={styles.menu}>
          <li className={`${styles.menuLi} ${styles.drawableMenu}`}>
            <NavLink
              to="/catalog"
              end
              className={({ isActive, isPending }) =>
                isActive
                  ? `${styles.menuItem} ${styles.activeMenu}`
                  : styles.menuItem
              }
            >
              КАТАЛОГ
              <div className={styles.line}></div>
            </NavLink>
            <ul className={styles.dropDown}>
              <li className={styles.menuLi}>
                <NavLink
                  to="/catalog/vibrolitya"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  вибролитая тротуарная плитка
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/catalog/vibropress"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  вибропрессованная тротуарная плитка
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/catalog/bordyuri"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  бордюры
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/catalog/vadastoki"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  водостоки
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/catalog/krishi"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  Крышки на столб/заборов
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/catalog/latok"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  Лоток водоотводный
                  <div className={styles.line}></div>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={styles.menuLi}>
            <NavLink
              to="/services"
              end
              className={({ isActive, isPending }) =>
                isActive
                  ? `${styles.menuItem} ${styles.activeMenu}`
                  : styles.menuItem
              }
            >
              УСЛУГИ
              <div className={styles.line}></div>
            </NavLink>
            <ul className={styles.dropDown}>
              <li className={styles.menuLi}>
                <NavLink
                  to="/services/ukladka"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  Укладка тротуарной плитки
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/services/kovka"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  Художественный ковка
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/services/dostavka"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  Доставка материалов
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/services/drenaj"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  Лоток водоотводный
                  <div className={styles.line}></div>
                </NavLink>
              </li>
              <li className={styles.menuLi}>
                <NavLink
                  to="/services/blagoustroistva"
                  end
                  className={({ isActive, isPending }) =>
                    isActive
                      ? `${styles.menuItem} ${styles.activeMenu}`
                      : styles.menuItem
                  }
                >
                  Благоустройство территори
                  <div className={styles.line}></div>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className={styles.menuLi}>
            <NavLink
              to="/portfolio"
              end
              className={({ isActive, isPending }) =>
                isActive
                  ? `${styles.menuItem} ${styles.activeMenu}`
                  : styles.menuItem
              }
            >
              ПОРТФОЛИО
              <div className={styles.line}></div>
            </NavLink>
          </li>
          <li className={styles.menuLi}>
            <NavLink
              to="/about_us"
              end
              className={({ isActive, isPending }) =>
                isActive
                  ? `${styles.menuItem} ${styles.activeMenu}`
                  : styles.menuItem
              }
            >
              О НАС
              <div className={styles.line}></div>
            </NavLink>
          </li>
          <li className={styles.menuLi}>
            <NavLink
              to="/contacts"
              end
              className={({ isActive, isPending }) =>
                isActive
                  ? `${styles.menuItem} ${styles.activeMenu}`
                  : styles.menuItem
              }
            >
              КОНТАКТЫ
              <div className={styles.line}></div>
            </NavLink>
          </li>
        </ul>
      </nav>
      <div className={styles.headerRight}>
        <div className={styles.phoneContainer}>
          <a className={styles.phoneItem} href="tel:89162335987">
            8 916 233 59 87
          </a>
          <a className={styles.phoneItem} href="tel:89850050506">
            8 985 005 05 06
          </a>
        </div>
      </div>
    </div>
  );
}
