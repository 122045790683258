import React from "react";
import styles from "./style.module.css";
import Title from "../../../components/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, Thumbs } from "swiper";
import { ReactComponent as ArrowSvg } from "../../../assets/LongArrow.svg";
import { Link } from "react-router-dom";

SwiperCore.use([Thumbs, Navigation, Pagination, Scrollbar]); //Navigation, Pagination, Scrollbar, A11y

export default function Index() {
  return (
    <div className={`${styles.container} galery`}>
      <Title
        text={"Галерея объектов"}
        style={{ alignSelf: "start", margin: "40px 0 1px" }}
      />
      <Link to="./portfolio" className={styles.showMore}>
        <span>ВСЕ ОБЪЕКТЫ</span>
        <ArrowSvg />
      </Link>
      <div className={styles.main}>
        <Swiper
          navigation
          scrollbar={{ draggable: true }}
          slidesPerView={
            window.innerWidth > 1024
              ? window.innerWidth / 390
              : window.innerWidth > 550
              ? window.innerWidth / 350
              : window.innerWidth / 320
          }
          // slidesPerView={4}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
        >
          <SwiperSlide>
            <img
              className={styles.img}
              src={require("./assets/1.jpg")}
              alt="Тротуарная плитка"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className={styles.img}
              src={require("./assets/2.jpg")}
              alt="Тротуарная плитка"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className={styles.img}
              src={require("./assets/3.jpg")}
              alt="Тротуарная плитка"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className={styles.img}
              src={require("./assets/4.jpg")}
              alt="Тротуарная плитка"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className={styles.img}
              src={require("./assets/1.jpg")}
              alt="Тротуарная плитка"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className={styles.img}
              src={require("./assets/2.jpg")}
              alt="Тротуарная плитка"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className={styles.img}
              src={require("./assets/3.jpg")}
              alt="Тротуарная плитка"
            />
          </SwiperSlide>
          <SwiperSlide>
            <img
              className={styles.img}
              src={require("./assets/4.jpg")}
              alt="Тротуарная плитка"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}
