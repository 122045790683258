export default {
  colors: [
    { title: "Серый", img: require("./assets/colors/seri.jpeg") },
    { title: "Красный", img: require("./assets/colors/krasni.jpg") },
    { title: "Коричневый", img: require("./assets/colors/karichnivi.jpg") },
    { title: "Желтый", img: require("./assets/colors/jyolti.jpg") },
    { title: "Белый", img: require("./assets/colors/beli.jpg") },
    { title: "Черный", img: require("./assets/colors/chyorni.jpg") },
    { title: "Темно серый", img: require("./assets/colors/tyomno-seri.jpg") },
  ],
  Сицилия: {
    images: [require("./assets/vibrolitya/Сицилия/1.jpg")],
    sizes: ["300x300x30"],
  },
  Ковёр: {
    images: [
      require("./assets/vibrolitya/Ковёр/1.jpeg"),
      require("./assets/vibrolitya/Ковёр/2.jpeg"),
    ],
    sizes: ["500x500x50", "400x400x50"],
  },
  Ялта: {
    images: [
      require("./assets/vibrolitya/Ялта/1.jpg"),
      require("./assets/vibrolitya/Ялта/2.jpg"),
      require("./assets/vibrolitya/Ялта/3.jpeg"),
      require("./assets/vibrolitya/Ялта/4.jpeg"),
      require("./assets/vibrolitya/Ялта/5.jpeg"),
    ],
    sizes: ["300x300x30"],
  },
  Осень: {
    images: [require("./assets/vibrolitya/Осень/1.jpg")],
    sizes: ["300x300x30"],
  },
  Ибица: {
    images: [],
    sizes: ["300x300x30"],
  },
  Тучка: {
    images: [
      require("./assets/vibrolitya/Тучка/1.jpg"),
      require("./assets/vibrolitya/Тучка/2.jpg"),
    ],
    sizes: ["300x300x30"],
  },
  Паркет: {
    images: [
      require("./assets/vibrolitya/Паркет/1.png"),
      require("./assets/vibrolitya/Паркет/2.jpg"),
      require("./assets/vibrolitya/Паркет/3.jpeg"),
      require("./assets/vibrolitya/Паркет/4.jpeg"),
    ],
    sizes: ["300x300x30"],
  },
  Гладкая: {
    images: [
      require("./assets/vibrolitya/Гладкая/1.jpg"),
      require("./assets/vibrolitya/Гладкая/2.jpg"),
      require("./assets/vibrolitya/Гладкая/3.jpg"),
      require("./assets/vibrolitya/Гладкая/4.jpeg"),
    ],
    sizes: ["500x500x50", "400x400x50", "300x300x30"],
  },
  Цветок: {
    images: [],
    sizes: ["350x350x50", "250x250x25"],
  },
  "Брусчатка гладкая": {
    images: [
      require("./assets/vibrolitya/Брусчатка гладкая/1.jpg"),
      require("./assets/vibrolitya/Брусчатка гладкая/2.jpg"),
      require("./assets/vibrolitya/Брусчатка гладкая/3.jpg"),
      require("./assets/vibrolitya/Брусчатка гладкая/4.jpg"),
      require("./assets/vibrolitya/Брусчатка гладкая/5.jpeg"),
      require("./assets/vibrolitya/Брусчатка гладкая/6.jpeg"),
      require("./assets/vibrolitya/Брусчатка гладкая/7.jpeg"),
      require("./assets/vibrolitya/Брусчатка гладкая/8.jpeg"),
      require("./assets/vibrolitya/Брусчатка гладкая/9.jpeg"),
      require("./assets/vibrolitya/Брусчатка гладкая/10.jpeg"),
      require("./assets/vibrolitya/Брусчатка гладкая/11.jpeg"),
    ],
    sizes: ["200х100х40", "200х100х50", "200х100х60"],
  },
  "12 кирпичей": {
    images: [
      require("./assets/vibrolitya/12 кирпичей/1.jpg"),
      require("./assets/vibrolitya/12 кирпичей/2.jpg"),
    ],
    sizes: ["500x500x50"],
  },
  "8 кирпичей": {
    images: [
      require("./assets/vibrolitya/8 кирпичей/1.jpg"),
      require("./assets/vibrolitya/8 кирпичей/2.jpg"),
      require("./assets/vibrolitya/8 кирпичей/3.jpg"),
    ],
    sizes: ["400х400х50", "300x300x30"],
  },
  "Английский булыжник": {
    images: [
      require("./assets/vibrolitya/Английский булыжник/1.jpeg"),
      require("./assets/vibrolitya/Английский булыжник/2.jpg"),
      require("./assets/vibrolitya/Английский булыжник/3.jpg"),
      require("./assets/vibrolitya/Английский булыжник/4.jpg"),
    ],
    sizes: ["250х125х50"],
  },
  Эко: {
    images: [
      require("./assets/vibrolitya/Эко/1.jpg"),
      require("./assets/vibrolitya/Эко/2.jpg"),
    ],
    sizes: ["265х265х60"],
  },
  Волна: {
    images: [
      require("./assets/vibrolitya/Волна/1.jpg"),
      require("./assets/vibrolitya/Волна/2.jpg"),
      require("./assets/vibrolitya/Волна/3.jpg"),
      require("./assets/vibrolitya/Волна/4.jpeg"),
    ],
    sizes: ["240х125х60"],
  },
  Окно: {
    images: [],
    sizes: ["350х350х50"],
  },
  Катушка: {
    images: [
      require("./assets/vibrolitya/Катушка/1.jpg"),
      require("./assets/vibrolitya/Катушка/2.jpg"),
      require("./assets/vibrolitya/Катушка/3.jpg"),
    ],
    sizes: ["225х140х60"],
  },
  Клевер: {
    images: [
      require("./assets/vibrolitya/Клевер/1.jpg"),
      require("./assets/vibrolitya/Клевер/2.jpg"),
      require("./assets/vibrolitya/Клевер/3.jpg"),
      require("./assets/vibrolitya/Клевер/4.jpg"),
      require("./assets/vibrolitya/Клевер/5.jpg"),
      require("./assets/vibrolitya/Клевер/6.jpeg"),
      require("./assets/vibrolitya/Клевер/7.jpeg"),
      require("./assets/vibrolitya/Клевер/8.jpeg"),
      require("./assets/vibrolitya/Клевер/9.jpeg"),
      require("./assets/vibrolitya/Клевер/10.jpeg"),
      require("./assets/vibrolitya/Клевер/11.jpeg"),
      require("./assets/vibrolitya/Клевер/12.jpeg"),
    ],
    sizes: ["267x133x45 — 267x133x60"],
  },
  "Рваная сетка": {
    images: [],
    sizes: ["250х250х25"],
    price: "330",
  },
  Чешуя: {
    images: [
      require("./assets/vibrolitya/Чешуя/1.jpeg"),
      require("./assets/vibrolitya/Чешуя/2.jpg"),
    ],
    sizes: ["245х190х60"],
  },
  Ромб: {
    images: [
      require("./assets/vibrolitya/Ромб/1.jpg"),
      require("./assets/vibrolitya/Ромб/2.jpg"),
      require("./assets/vibrolitya/Ромб/3.jpg"),
      require("./assets/vibrolitya/Ромб/4.png"),
      require("./assets/vibrolitya/Ромб/5.jpg"),
    ],
    sizes: ["190х33х45"],
  },
  Паутинка: {
    images: [],
    sizes: ["350х350х50"],
  },
  Соты: {
    images: [
      require("./assets/vibrolitya/Соты/1.jpg"),
      require("./assets/vibrolitya/Соты/2.jpg"),
    ],
    sizes: ["255х175х45"],
  },
  Марсель: {
    images: [
      require("./assets/vibrolitya/Марсель/1.jpg"),
      require("./assets/vibrolitya/Марсель/2.jpg"),
      require("./assets/vibrolitya/Марсель/3.jpeg"),
      require("./assets/vibrolitya/Марсель/4.jpeg"),
      require("./assets/vibrolitya/Марсель/5.jpeg"),
      require("./assets/vibrolitya/Марсель/6.jpeg"),
      require("./assets/vibrolitya/Марсель/7.jpeg"),
      require("./assets/vibrolitya/Марсель/8.jpeg"),
      require("./assets/vibrolitya/Марсель/9.jpeg"),
    ],
    sizes: ["300х300х50"],
    price: {
      seri: "850",
      cvetnoi: "950",
    },
  },
  "3 доски": {
    images: [require("./assets/vibrolitya/3 доски/1.jpg")],
    sizes: ["400х400х50", "300х300х30"],
  },
  Салаир: {
    images: [],
    sizes: ["300х250х50"],
  },
  "Корабельная доска": {
    images: [
      require("./assets/vibrolitya/Корабельная доска/1.jpg"),
      require("./assets/vibrolitya/Корабельная доска/2.jpg"),
      require("./assets/vibrolitya/Корабельная доска/3.jpg"),
    ],
    sizes: ["600х150х50"],
  },
  "Антик квадраты": {
    images: [
      require("./assets/vibrolitya/Антик квадраты/1.jpg"),
      require("./assets/vibrolitya/Антик квадраты/2.jpg"),
      require("./assets/vibrolitya/Антик квадраты/3.jpg"),
    ],
    sizes: ["300×300х45"],
  },
  "Вибропрессованная-Брусчатка": {
    images: [
      require("./assets/vibropress/Брусчатка/1.jpg"),
      require("./assets/vibropress/Брусчатка/2.jpg"),
      require("./assets/vibropress/Брусчатка/3.jpg"),
      require("./assets/vibropress/Брусчатка/4.jpg"),
      require("./assets/vibropress/Брусчатка/5.jpg"),
    ],
    sizes: [
      "200х100х40",
      "200х100х50",
      "200х100х60",
      "200х100х70",
      "200х100х80",
      "200х100х100",
    ],
  },
  "Вибропрессованная-Квадрат": {
    images: [require("./assets/vibropress/Квадрат/1.jpg")],
    sizes: [
      "150х150х60",
      "150х150х70",
      "300х300х80",
      "330х330х60",
      "330х330х70",
      "400х400х60",
      "400х400х70",
      "500х500х70",
      "500х500х80",
      "500х500х100",
    ],
  },
  "Вибропрессованная-Катушка": {
    images: [
      require("./assets/vibropress/Катушка/1.jpg"),
      require("./assets/vibropress/Катушка/2.jpg"),
      require("./assets/vibropress/Катушка/3.jpeg"),
      require("./assets/vibropress/Катушка/4.jpg"),
      require("./assets/vibropress/Катушка/5.jpg"),
      require("./assets/vibropress/Катушка/5.jpg"),
    ],
    sizes: ["200х165х60", "200х165х70"],
  },
  "Вибропрессованная-Старый Город": {
    images: [
      require("./assets/vibropress/Старый-Город/1.jpeg"),
      require("./assets/vibropress/Старый-Город/2.jpg"),
      require("./assets/vibropress/Старый-Город/3.jpg"),
      require("./assets/vibropress/Старый-Город/4.jpg"),
      require("./assets/vibropress/Старый-Город/5.jpg"),
      require("./assets/vibropress/Старый-Город/6.jpg"),
      require("./assets/vibropress/Старый-Город/7.jpg"),
    ],
    sizes: [
      "180х120х60, 120х120х60, 90х120х60",
      "180х120х70, 120х120х70, 90х120х70",
    ],
    price: "350",
  },
  "Вибропрессованная-Волна": {
    images: [
      require("./assets/vibropress/Волна/1.jpg"),
      require("./assets/vibropress/Волна/2.jpg"),
    ],
    sizes: ["225х112х60", "225х112х70", "225х112х80"],
  },
  "Садовый бордюр": {
    images: [
      require("./assets/bordyur/sadovi1.jpg"),
      require("./assets/bordyur/sadovi2.jpg"),
    ],
    sizes: ["1000х200х80", "500х200х80", "500x200×40"],
  },
  "Дорожный бордюр": {
    images: [
      require("./assets/bordyur/dorojni1.jpeg"),
      require("./assets/bordyur/dorojni2.jpg"),
    ],
    sizes: ["1000х300х150"],
  },
  "Бордюр тротуарный": {
    images: [
      require("./assets/bordyur/tratuarni1.jpg"),
      require("./assets/bordyur/tratuarni2.jpg"),
    ],
    sizes: ["500х200х60", "1000x200×80"],
  },
  Водосток: {
    images: [
      require("./assets/vodostok/1.jpg"),
      require("./assets/vodostok/2.jpg"),
      require("./assets/vodostok/3.jpeg"),
    ],
    sizes: ["1000х300х300", "1000х400х400"],
  },
  "Крышки на столбы": {
    images: [
      require("./assets/krishi/stolb/1.jpeg"),
      require("./assets/krishi/stolb/2.jpg"),
    ],
    sizes: ["390х390"],
  },
  "Крышки на столбы узорный": {
    images: [require("./assets/krishi/uzorni/1.jpg")],
    sizes: ["450х450", "490х490"],
  },
  "Крышки на забор": {
    images: [
      require("./assets/krishi/zabor/1.jpg"),
      require("./assets/krishi/zabor/2.jpg"),
    ],
    sizes: ["270х390", "350х390", "500х180"],
  },
  "Лоток водоотводный 40×40×100": {
    images: [
      require("./assets/latok/40×40×100/1.jpeg"),
      require("./assets/latok/40×40×100/2.jpeg"),
      require("./assets/latok/40×40×100/3.jpeg"),
    ],
    colors: false,
    price: 1300,
  },
  "Лоток водоотводный 30×30×100": {
    images: [
      require("./assets/latok/30×30×100/1.jpeg"),
      require("./assets/latok/30×30×100/2.jpeg"),
    ],
    colors: false,
    price: 1100,
  },
  "Лоток водоотводный 16.5×15×50": {
    images: [
      require("./assets/latok/17×15×50/1.jpeg"),
      require("./assets/latok/17×15×50/2.jpeg"),
      require("./assets/latok/17×15×50/3.jpeg"),
    ],
    colors: false,
    price: 500,
  },
};
