import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ShopSvg } from "../../assets/Shop.svg";
import { setShowShopModal } from "../../store/AppSlice";

export default function ShopButton() {
  const dispatch = useDispatch();
  const shopItems = useSelector(({ app }) => app.shopItems);

  if (!shopItems.length) {
    return null;
  }

  return (
    <div
      onClick={() => dispatch(setShowShopModal(true))}
      style={{
        position: "fixed",
        zIndex: 9999,
        right: "60px",
        top: "80px",
        backgroundColor: "#fff",
        width: "70px",
        height: "70px",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0px 0px 14px -2px rgba(81, 81, 81, 0.3)",
        cursor: "pointer",
      }}
    >
      <ShopSvg />
      <div
        style={{
          width: "25px",
          height: "25px",
          color: "#fff",
          borderRadius: "25px",
          position: "absolute",
          right: "-3px",
          bottom: "-3px",
          textAlign: "center",
          lineHeight: "27px",
          fontSize: "13px",
          fontFamily: "GothamPro",
        }}
      >
        {shopItems.length}
      </div>
    </div>
  );
}
