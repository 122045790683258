import React from "react";
import styles from "./style.module.css";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

export default function Index() {
  return (
    <div className={styles.container}>
      <ParallaxProvider>
        <Parallax speed={-10}>
          <div className={styles.parallax}></div>
        </Parallax>
        <div className={styles.main}>
          <div className={`${styles.glass}`}>
            <h1>
              РОСГОСТСТРОЙ - команда настоящих профессионалов и энтузиастов
              своего дела.
            </h1>
          </div>
          <div className={`${styles.glass} ${styles.glass2}`}>
            <p>
              Найден для вас красивую идею мощения сада и реализуем ее под ключ!
            </p>
            <p>
              Поможем выбрать цвет и формат плитки. Подберем стиль будущего
              ландшафта от классического го хей-тека.
            </p>
          </div>
        </div>
      </ParallaxProvider>
    </div>
  );
}
