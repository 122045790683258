import Home from "./screens/Home";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Portfolio from "./screens/Portfolio";
import AboutUs from "./screens/AboutUs/index";
import Contacts from "./screens/Contacts";
import Services from "./screens/Services";
import Catalog from "./screens/Catalog";
import CatalogList from "./screens/CatalogsList";
import ProductPage from "./screens/ProductPage/index";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ServicePage from "./screens/ServicePage";
import ShopButton from "./components/ShopModal/ShopButton";
import ShopModal from "./components/ShopModal";
import CallBackModal from "./components/CallBackModal";
import SendMailSuccessfullyModal from "./components/sendMailSuccessfullyModal";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/catalog",
    element: <Catalog />,
  },
  {
    path: "/services",
    element: <Services />,
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
  },
  {
    path: "/about_us",
    element: <AboutUs />,
  },
  {
    path: "/contacts",
    element: <Contacts />,
  },
  {
    path: "/services/:serviceName",
    element: <ServicePage />,
  },

  {
    path: "/catalog/:catalogName",
    element: <CatalogList />,
  },
  {
    path: "/productPage/:productName",
    element: <ProductPage />,
  },
]);
function App() {
  return (
    <Provider store={store}>
      <ShopButton />
      <ShopModal />
      <CallBackModal />
      <SendMailSuccessfullyModal />
      <RouterProvider router={router} />
    </Provider>
  );
}

export default App;
