import React, { useState } from "react";
import styles from "./header.module.css";
import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import { NavLink } from "react-router-dom";
import { ReactComponent as BurgerMenuSvg } from "./assets/burgerMenuSvg.svg";
import { ReactComponent as PhoneSvg } from "./assets/phone-icon.svg";

export default function HeaderMobile() {
  const [active, setActive] = useState(false);
  return (
    <div style={{ width: window.innerWidth }}>
      <div className={styles.container}>
        <div className="burgerMenu" onClick={() => setActive(!active)}>
          <BurgerMenuSvg width={30} height={30} />
        </div>
        <NavLink to="/" className={styles.logo}>
          <LogoSvg />
          <span className={styles.logoText}>РОСГОСТСТРОЙ</span>
        </NavLink>
        <a href="tel:89162335987">
          <PhoneSvg width={26} height={26} />
        </a>
      </div>
      <div>
        <nav>
          <ul
            className={styles.menu}
            style={{ maxHeight: active ? "500px" : 0 }}
          >
            <li
              className={`${styles.menuLi} ${styles.drawableMenu}`}
              style={{ marginTop: "20px" }}
            >
              <NavLink
                to="/catalog"
                end
                className={({ isActive, isPending }) =>
                  isActive
                    ? `${styles.menuItem} ${styles.activeMenu}`
                    : styles.menuItem
                }
              >
                КАТАЛОГ
                <div className={styles.line}></div>
              </NavLink>
            </li>
            <li className={styles.menuLi}>
              <NavLink
                to="/services"
                end
                className={({ isActive, isPending }) =>
                  isActive
                    ? `${styles.menuItem} ${styles.activeMenu}`
                    : styles.menuItem
                }
              >
                УСЛУГИ
                <div className={styles.line}></div>
              </NavLink>
            </li>
            <li className={styles.menuLi}>
              <NavLink
                to="/portfolio"
                end
                className={({ isActive, isPending }) =>
                  isActive
                    ? `${styles.menuItem} ${styles.activeMenu}`
                    : styles.menuItem
                }
              >
                ПОРТФОЛИО
                <div className={styles.line}></div>
              </NavLink>
            </li>
            <li className={styles.menuLi}>
              <NavLink
                to="/about_us"
                end
                className={({ isActive, isPending }) =>
                  isActive
                    ? `${styles.menuItem} ${styles.activeMenu}`
                    : styles.menuItem
                }
              >
                О НАС
                <div className={styles.line}></div>
              </NavLink>
            </li>
            <li className={styles.menuLi} style={{ marginBottom: "30px" }}>
              <NavLink
                to="/contacts"
                end
                className={({ isActive, isPending }) =>
                  isActive
                    ? `${styles.menuItem} ${styles.activeMenu}`
                    : styles.menuItem
                }
              >
                КОНТАКТЫ
                <div className={styles.line}></div>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
