export default {
  vibropress: {
    title: "Вибропрессованная тротуарная плитка",
    colors: [
      "Серый",
      "Красный",
      "Коричневый",
      "Желтый",
      "Белый",
      "Черный",
      "Темно серый",
    ],
    products: [
      {
        title: "Брусчатка",
        image: require("./assets/vibropres/bruschatka.png"),
      },
      {
        title: "Квадрат",
        image: require("./assets/vibropres/kvadrat.png"),
      },
      {
        title: "Катушка",
        image: require("./assets/vibropres/katushka.png"),
      },
      {
        title: "Старый Город",
        image: require("./assets/vibropres/stari-gord.png"),
      },
      {
        title: "Волна",
        image: require("./assets/vibropres/volna.png"),
      },
    ],
  },
  vibrolitya: {
    title: "Вибролитая тротуарная плитка",
    products: [
      {
        title: "Сицилия",
        image: require("./assets/vibrolitya/silicia.jpg"),
      },
      {
        title: "Ковёр",
        image: require("./assets/vibrolitya/kover.jpg"),
      },
      {
        title: "Ялта",
        image: require("./assets/vibrolitya/yalta.jpg"),
      },
      {
        title: "Осень",
        image: require("./assets/vibrolitya/osen.jpg"),
      },
      // {
      //   title: "Листопад",
      //   image: require("./assets/vibrolitya/listopad.jpg"),
      // },
      {
        title: "Ибица",
        image: require("./assets/vibrolitya/ibica.jpeg"),
      },
      {
        title: "Тучка",
        image: require("./assets/vibrolitya/tuchka.jpeg"),
      },
      {
        title: "Паркет",
        image: require("./assets/vibrolitya/parket.jpeg"),
      },
      {
        title: "Гладкая",
        image: require("./assets/vibrolitya/gladki.jpg"),
      },
      {
        title: "Цветок",
        image: require("./assets/vibrolitya/cvetok.jpg"),
      },
      {
        title: "Брусчатка гладкая",
        image: require("./assets/vibrolitya/kirpich.png"),
      },
      {
        title: "12 кирпичей",
        image: require("./assets/vibrolitya/12kirpich.jpg"),
      },
      {
        title: "8 кирпичей",
        image: require("./assets/vibrolitya/8kirpich.jpg"),
      },
      {
        title: "Английский булыжник",
        image: require("./assets/vibrolitya/bulijnik.png"),
      },
      {
        title: "Эко",
        image: require("./assets/vibrolitya/eko.jpg"),
      },
      {
        title: "Волна",
        image: require("./assets/vibrolitya/valna.jpeg"),
      },
      {
        title: "Окно",
        image: require("./assets/vibrolitya/okno.jpg"),
      },
      {
        title: "Катушка",
        image: require("./assets/vibrolitya/katushka.jpeg"),
      },
      {
        title: "Клевер",
        image: require("./assets/vibrolitya/klever.jpeg"),
      },
      {
        title: "Рваная сетка",
        image: require("./assets/vibrolitya/setka.jpg"),
      },
      {
        title: "Чешуя",
        image: require("./assets/vibrolitya/cheshuya.jpeg"),
      },
      {
        title: "Ромб",
        image: require("./assets/vibrolitya/rombik.jpeg"),
      },
      {
        title: "Паутинка",
        image: require("./assets/vibrolitya/pautina.jpeg"),
      },
      {
        title: "Соты",
        image: require("./assets/vibrolitya/soti.jpeg"),
      },
      {
        title: "Марсель",
        image: require("./assets/vibrolitya/marsel.png"),
      },
      {
        title: "3 доски",
        image: require("./assets/vibrolitya/tri-doska.png"),
      },
      {
        title: "Салаир",
        image: require("./assets/vibrolitya/salair.png"),
      },
      {
        title: "Корабельная доска",
        image: require("./assets/vibrolitya/doska.jpg"),
      },
      {
        title: "Антик квадраты",
        image: require("./assets/vibrolitya/antik.jpg"),
      },
    ],
  },
  bordyuri: {
    title: "Бордюры",
    products: [
      {
        title: "Садовый бордюр",
        image: require("./assets/bordyuri/bordyur-sadovi.jpeg"),
        sizes: ["1000х200х80", "500х200х80", "500x200×40"],
      },
      {
        title: "Дорожный бордюр",
        image: require("./assets/bordyuri/bordyur-darojni.jpeg"),
        sizes: ["1000х300х150"],
      },
      {
        title: "Бордюр тротуарный",
        image: require("./assets/bordyuri/bordyur-darojni.jpeg"),
        sizes: ["500х200х60", "1000x200×80"],
      },
    ],
  },
  vadastoki: {
    title: "Водостоки",
    products: [
      {
        title: "Водосток",
        image: require("./assets/vodostoki/1.jpg"),
      },
    ],
  },
  krishi: {
    title: "Крышки на столб/заборов",
    products: [
      {
        title: "Крышки на столбы",
        image: require("./assets/krishi/1.jpeg"),
        sizes: ["390х390"],
      },
      {
        title: "Крышки на столбы узорный",
        image: require("./assets/krishi/2.jpg"),
        sizes: ["450х450", "490х490"],
      },
      {
        title: "Крышки на забор",
        image: require("./assets/krishi/3.jpg"),
        sizes: ["270х390", "350х390", "500х180"],
      },
    ],
  },
  latok: {
    title: "Лоток водоотводный",
    products: [
      {
        title: "Лоток водоотводный 40×40×100",
        image: require("./assets/latok/40×40×100.jpeg"),
      },
      {
        title: "Лоток водоотводный 30×30×100",
        image: require("./assets/latok/30×30×100.jpeg"),
      },
      {
        title: "Лоток водоотводный 16.5×15×50",
        image: require("./assets/latok/17×15×50.jpeg"),
      },
    ],
  },
};
