import React from "react";
import styles from "./style.module.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { useParams } from "react-router-dom";
import catalogs_data from "./catalogs_data";
import ProductCard from "../../components/ProductCard/ProductCard";
import useScrollToTop from "../../helpers/useScrollToTop";
export default function CatalogList() {
  const { catalogName } = useParams();
  const data = catalogs_data[catalogName];

  useScrollToTop();

  return (
    <div>
      <Header />
      <div className={styles.main}>
        <Title text={data.title} />
        <div className={styles.catalogContainer}>
          {data.products.map((item, index) => (
            <ProductCard
              key={index}
              image={item.image}
              title={item.title}
              catalogName={catalogName}
              showPrice={false}
              containerStyle={{ marginBottom: "50px" }}
            />
          ))}
        </div>
        <Footer />
      </div>
    </div>
  );
}
