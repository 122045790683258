export default [
  // {
  //   title: "Брусчатка",
  //   image: require("../../CatalogsList/assets/vibropres/bruschatka.png"),
  //   catalogName: "vibropress",
  // },
  // {
  //   title: "Старый Город",
  //   image: require("../../CatalogsList/assets/vibropres/stari-gord.png"),
  //   catalogName: "vibropress",
  // },
  // {
  //   title: "Английский булыжник",
  //   image: require("../../CatalogsList/assets/vibrolitya/bulijnik.png"),
  // },
  // {
  //   title: "Кленовый лист",
  //   image: require("../../CatalogsList/assets/vibrolitya/list.jpg"),
  // },
  // {
  //   title: "Ромб",
  //   image: require("../../CatalogsList/assets/vibrolitya/rombik.jpeg"),
  // },
  // {
  //   title: "Соты",
  //   image: require("../../CatalogsList/assets/vibrolitya/soti.jpeg"),
  // },
  {
    title: "Марсель",
    image: require("../../CatalogsList/assets/vibrolitya/marsel.png"),
  },
  {
    title: "Сицилия",
    image: require("../../CatalogsList/assets/vibrolitya/silicia.jpg"),
  },
  {
    title: "Ковёр",
    image: require("../../CatalogsList/assets/vibrolitya/kover.jpg"),
  },
  {
    title: "Ялта",
    image: require("../../CatalogsList/assets/vibrolitya/yalta.jpg"),
  },
  {
    title: "Осень",
    image: require("../../CatalogsList/assets/vibrolitya/osen.jpg"),
  },
  {
    title: "Тучка",
    image: require("../../CatalogsList/assets/vibrolitya/tuchka.jpeg"),
  },
  {
    title: "Паркет",
    image: require("../../CatalogsList/assets/vibrolitya/parket.jpeg"),
  },
  {
    title: "Гладкая",
    image: require("../../CatalogsList/assets/vibrolitya/gladki.jpg"),
  },
  {
    title: "12 кирпичей",
    image: require("../../CatalogsList/assets/vibrolitya/12kirpich.jpg"),
  },
  {
    title: "8 кирпичей",
    image: require("../../CatalogsList/assets/vibrolitya/8kirpich.jpg"),
  },
  {
    title: "3 доски",
    image: require("../../CatalogsList/assets/vibrolitya/tri-doska.png"),
  },
];
