import React from "react";
import styles from "./style.module.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Title from "../../components/Title";
import { useParams } from "react-router-dom";
import service_data from "./service_data";
import useScrollToTop from "../../helpers/useScrollToTop";
export default function ServicePage() {
  const { serviceName } = useParams();
  const data = service_data[serviceName];
  useScrollToTop();
  return (
    <div>
      <Header />
      <div className={styles.main}>
        <Title text={data.title} />
        <p className={styles.subTitle}>{data.subTitle}</p>

        {data.description.split("<br/>").map((text, index) => (
          <p className={styles.description} key={index}>
            {text}
          </p>
        ))}
        {data.list && (
          <ul style={{ padding: 0, margin: "10px 20px" }}>
            {data.list.map((item, index) => (
              <li className={styles.description} key={index}>
                {item}
              </li>
            ))}
          </ul>
        )}
        {data.imagesStyle !== "dostavka" ? (
          <div className={styles[data.imagesStyle]}>
            {data.images.map((img, index) => (
              <img
                src={img}
                alt={data.title}
                className={styles.img}
                key={index}
              />
            ))}
          </div>
        ) : (
          <div className={styles[data.imagesStyle]}>
            <div className={styles.column}>
              <img
                src={data.images[0]}
                alt={data.title}
                className={styles.img}
              />
              <img
                src={data.images[1]}
                alt={data.title}
                className={styles.img}
              />
            </div>
            <img src={data.images[2]} alt={data.title} className={styles.img} />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}
