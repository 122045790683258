import React from "react";
import Button from "../../../components/Button";
import "./style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import { setShowCallBackModal } from "../../../store/AppSlice";
// import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";

const slideData = [
  {
    img: require("./assets/slide1.jpg"),
    alt: "«РосГостСрой» - строительная компания",
  },
  {
    img: require("./assets/slide2.jpg"),
    alt: "Купить тротуарная плитка",
  },
  {
    img: require("./assets/slide3.jpg"),
    alt: "Художественная ковка на заказ",
  },
  {
    img: require("./assets/slide4.jpg"),
    alt: "Укладка тротуарной плитки",
  },
];
export default function Slider() {
  // const dispatch = useDispatch();
  const sliderHeight = window.innerHeight - 70;

  return (
    <div className="sliderContainer">
      <div className="sliderItem">
        <Carousel
          autoPlay
          infiniteLoop
          interval={6000}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          transitionTime={2000}
          renderIndicator={(onClickHandler, isSelected, index) => (
            <span
              key={index}
              style={{
                fontFamily: "DIN Condensed",
                fontWeight: 700,
                fontSize: "30px",
                lineHeight: "36px",
                textAlign: "center",
                color: "rgb(18, 18, 18)",
                opacity: isSelected ? 1 : 0.59,
                cursor: "pointer",
                margin: "5px",
              }}
              onClick={onClickHandler}
            >
              0{index + 1}
            </span>
          )}
          topOnHover={false}
        >
          {slideData.map((item, index) => (
            <img
              key={index}
              className="img"
              src={item.img}
              style={{
                height: window.innerWidth > 480 ? sliderHeight + "px" : "450px",
                minHeight: window.innerWidth > 480 ? "650px" : "450px",
              }}
              alt={item.alt}
            />
          ))}
        </Carousel>
        <div className="glass" style={{ height: "100%" }}>
          <p className="title">Создайте с нами пространство мечты!</p>
          <p className="subTitle">
            Консультация архитектора и помощь в подборе материалов для
            благоустройства
          </p>
          <div className="btnsContainer">
            <Button
              fill
              text="Заказать звонок"
              href="tel:89162335987"
              // onClick={() => dispatch(setShowCallBackModal(true))}
            />
            <NavLink to="/portfolio" end style={{ textDecoration: "none" }}>
              <Button
                text="Смотреть все проекты"
                arrow
                style={{
                  marginLeft: window.innerWidth > 768 ? "25px" : 0,
                  textTransform: "none",
                }}
              />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
