export default {
  ukladka: {
    title: "Укладка тротуарной плитки",
    subTitle:
      "Вы можете не только купить у нас плитку, но и получить качественную услугу по укладке. Мы позаботимся о вашем комфорте и обеспечим качество и безопасность проекта.",
    description: `С помощью плитки выкладываются пешеходные дорожки, подъезд для машин, зоны отдыха или же просто украшения сада. Благодаря огромному разнообразию оттенков, узоров и непосредственно форм можно создать практически абсолютно любой дизайн.Одной из самых важных и основных особенностей монтажа тротуарной плитки является состыковка самих плиток, что требует особого мастерства при заполнении кругового 
        пространства или же создании узора, при котором требуется точно входить в пазы. Также существует такой метод как замочное соединение. 
        Однако, оно не предполагает прочного соединения при помощи сцеплений граней плиток. Скорее оно выкладывается по технологии, немного п
        одобной замочному соединению пола, в частности искусственного паркета. <br/>
        В свою очередь укладка прямоугольной и квадратной тротуарной плитки без расхождения швов представляет собой непростую операцию, 
        выполнить которую качественно без опыта будет сложно. Но рабочая бригада, специализирующаяся на данном направлении сделает это довольно быстро и легко. К тому же, они точно будут знать какой вид подложки лучше подобрать.Основным направлением деятельности строительной 
        компании «РОСГОСТСТРОЙ» является производство, доставка и укладка тротуарной плитки в Москве и Московском области. За свою многолетнюю историю, компания успела зарекомендовать себя как добросовестный и успешный производитель.Процесс производства поддается строгому 
        контролю на предмет соблюдения технологических норм и стандартов качества. Ассортимент нашей продукции богат на различные конфигурации, размеры и цветовые решения. Ценовая политика компании достаточно демократичная, к тому же, существует предусмотренная система скидок. Оформив заказ у нас, Вы гарантированно получаете консультацию мастеров относительно выбора подходящего материала основы, учитывая 
        особенности геодезии территории, а также экологичный и высокопрочный материал для дорожного покрытия, который прослужит долгие годы.
        Ко всему прочему, фигурные элементы мощения нашего производства не теряют окраску при воздействии солнечных лучей и не утрачивают 
        целостности при низких температурах воздуха, что предполагает привлекательный и эстетичный внешний вид территории.Работа по укладке 
        тротуарной плитки начинается с оформления заявки (по телефону или email) и выезда специалиста на участок. В первую очередь, мы проводим 
        замеры (если нет готового плана территории). `,

    images: [
      require("./assets/ukladka/1.jpg"),
      require("./assets/ukladka/2.jpg"),
      require("./assets/ukladka/3.jpg"),
    ],
    imagesStyle: "row-flex-end",
  },
  kovka: {
    title: "Художественная ковка",

    description: `Художественная ковка — изготовление изящных предметов быта из металлов методами ковки. Художественная ковка отличается от обычной ковки тем, что кованые изделия приобретают художественную ценность, становятся произведением искусства.
    Художественная ковка используется в архитектуре для создания грандиозности зданий.
    Изделиями художественной ковки могут быть: кованые ворота, калитки, заборы, вывески, козырьки, садовые качели, беседки, флюгеры, решетки, кованые лестницы и перила, люстры, подсвечники и даже кованые кровати и столики.
    <br/> <br/>
    Наша компания занимается не только продукцией строй материалов из бетона, мы также занимаемся художественной ковкой 
    из высококачественного металла. Каждая работа наших кузнецов превращается практически в произведение искусства, 
    каждый предмет индивидуален! И любой заказчик может спокойно похвастаться, что он единственный обладатель данного кованного произведения. В основном художественную ковку применяют, как в помещении, так и за пределами него. В помещении предметы художественной ковки-это лестницы, перила, подсвечники и люстры, даже некоторые заказчики предпочитают такой интерьер   
    из ковки, как кровать и даже журнальные и обеденные столы. За пределами помещения ковку используют для придания 
    красоты главных ворот , заборов, вывесок, козырьков и мн.другое. Некоторые предпочитают даже художественную ковку и для своих детишек, отстраивая в саду качели из ковки. У нас в наличии большой ассортимент уже изготовленный нашими кузнецами предметов, которые вы можете просмотреть ниже(фото). Одно из преимуществ изделий из художественной ковки это конечно 
    же долговечность предмета, т.к изделия из высококачественного металла. Размеры и габариты для нас не имеют значения, любое пожелание нашего клиента мы воплотим в реальность.`,

    images: [
      require("./assets/kovka/1.jpg"),
      require("./assets/kovka/2.jpg"),
      require("./assets/kovka/3.jpg"),
    ],
    imagesStyle: "row-flex-center",
  },
  dostavka: {
    title: "Доставка строительных материалов",

    description: `Независимо от того , будете ли Вы заказывать у нас товар того или иного вида , у нас действует гибкая система доставок 
    по всей Московской области. Мы имеем большой парк грузоперевозочной и погрузочной техники которая доставит ваш 
    товар в заданные сроки, гарантируя их сохранность и целостность. Мы перевозим любой строительный материал, тротуарную плитку в Павло-Посадском районе, в независимости от веса или габарита. С помощью наших кранов манипуляторов наши специалисты с легкостью загрузят Ваш товар будь это тротуарная плитка на поддонах или бордюрный камень. Наши самосвалы с различным тоннажем в заданные сроки доставят ваш песок или щебень. Особое внимание мы уделяем перевозке негабаритных и хрупких товаров, где нужна специальная упаковка для целостности данного товара. Один из них тротуарная плитка, ведь если ее не упаковать в паллеты они очень уязвимы. Грузовик с паллетницей с краном манипулятором идеально создан для перевозки кирпичей, перевозки тротуарной плитки, отлично подходит для перевозки железобетонных плит. У нас также есть кран манипулятор с ротатором (это устройство,которое вращает грузозахватное приспособление на 360 градусов). Им можно поставить паллету идеально и максимально быстро и точно в зону разгрузки .`,

    images: [
      require("./assets/dostavka/1.jpg"),
      require("./assets/dostavka/2.jpg"),
      require("./assets/dostavka/3.jpg"),
    ],
    imagesStyle: "dostavka",
  },
  drenaj: {
    title: "Лоток водоотводный",

    description: `Дрена́ж (от франц. Drainage) это удаление подземных (грунтовых) либо поверхностных вод с земельных участков и от фундаментов зданий или сооружений. удаление воды с поверхности земли либо подземных вод. Удаление грунтовых, сточных и талых вод необходимое условие для защиты земельного участка, дороги переувлажнения, заболачивания и проваливания и фундамента от разрушения.
    <br/>
    Дренажная система играет важную роль в эксплуатации зданий, находящихся на участке (высокий уровень грунтовых вод способствует разрушению фундамента) и эксплуатации самого участка. Поэтому к обустройству дренажной системы нужно отнестись с максимальной ответственностью. Наличие штата высокопрофессиональных специалистов с большим опытом работы и собственного парка современной техники, позволяет нам гарантировать высокое качество выполняемых дренажных работ и бесперебойное функционирование устанавливаемых нами дренажных систем.
    <br/><br/>
    Зачем нужно устройство дренажа на участке?
    <br/><br/>
    Каждый второй загородный участок страдает от избытка влаги в грунте, что негативно влияет на покрытия, газоны и вообще портит внешний вид территории. Обычно проблема переувлажнения обусловлена близко залегающими глинами и суглинками с низким коэффициентом фильтрации. Такие грунты очень медленно пропускают через себя дождевую и талую воду, приводят к ее скоплению и застою в верхнем растительном слое. Поэтому необходимо проводить осушение участка с высоким уровнем грунтовых вод.
    `,

    images: [
      require("./assets/drenaj/1.jpeg"),
      require("./assets/drenaj/2.jpeg"),
      require("./assets/drenaj/3.jpeg"),
      require("./assets/drenaj/4.jpeg"),
      require("./assets/drenaj/5.jpeg"),
      require("./assets/drenaj/6.jpeg"),
      require("./assets/drenaj/7.jpeg"),
      require("./assets/drenaj/8.jpeg"),
      require("./assets/drenaj/9.jpeg"),
      require("./assets/drenaj/11.jpeg"),
      require("./assets/drenaj/10.jpeg"),
      require("./assets/drenaj/13.jpeg"),
    ],
    imagesStyle: "row-flex-end",
  },
  blagoustroistva: {
    title: "Благоустройство территории",

    description: `Для живописности пейзажа создаются пруды и каскады ручьев. Размещение дорожек, тропинок и площадок, а также расстановка легких строений, фресок, ваз и скульптур.
    К основным элементам благоустройства территории относят прокладку дорожно-тропиночной сети, возведение малых архитектурных форм как декоративного, так и утилитарного характера. К ним относят пандусы и лестницы, подпорные стенки и мостики, словом, все, что облегчает передвижение по участку, создает эстетическое восприятие и сопряжение различных форм рельефа в единое целое.
    <br/> <br/> 
    Компания «РОСГОСТСТРОЙ» в своем составе имеет квалифицированные бригады, оснащенные собственной техникой, для выполнения работ любой сложности по благоустройству территории.
    На подготовительном этапе наши специалисты помогут вам продумать все технические детали проекта, учесть наличие зеленых насаждений и оборудование водостоков, составить смету, подобрать и правильно рассчитать количество необходимых строительных материалов, а также осуществить их доставку на объект.
    Работы могут быть выполнены как на объектах городской инфраструктуры, так и на объектах частной индивидуальной постройки.
    Перечень предоставляемых работ по благоустройству территории:`,
    list: [
      "обмер участка, фотофиксация, топосъемка;",
      "создание общей концепции, эскизное проектирование;",
      "подготовка пакета рабочих чертежей. Составление и оптимизация сметы;",
      "подготовка территории, устройство дренажа и электросетей;",
      "укладка (мощение) тротуарной плитки, строительство подпорных стенок и лестниц;",
      "замена старых и установка новых садовых и дорожных бордюров;",
      "установка люков, дренажных колодцев и линейного водоотведения (линий лотков с решетками);",
      "установка газонной решетки для организации зеленой эко-парковки;",
      "устройство рокариев, сухих ручьев и установка малых архитектурных форм из архитектурного бетона (скамеек, цветочниц, урн и т. д.).",
      "дендроплан. Выбор и посадка растений. Устройство газонов, цветников и альпинариев;",
      "искусственные водоемы, водопады, фонтаны и гроты. На стоимость укладки (мощения) тротуарной плитки влияют следующие факторы:",
      "степень трудоемкости подготовительного процесса (демонтаж старого покрытия и т.д.);",
      "вид основания (пешеходная зона, легковой или грузовой транспорт);",
      "тип мощения (прямолинейное или круговое, наличие сложного рисунка, наличие микса из нескольких цветов и т.д.).",
    ],
    images: [
      require("./assets/blagoustroistva/1.jpg"),
      require("./assets/blagoustroistva/2.jpg"),
      require("./assets/blagoustroistva/3.jpg"),
    ],
    imagesStyle: "row-flex-center",
  },
};
