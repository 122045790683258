import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openedProductPage: "",
  showShopModal: false,
  shopItems: [],
  showCallBackModal: false,
  afterSendMail: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setOpenedProductPage: (store, action) => {
      return {
        ...store,
        openedProductPage: action.payload,
      };
    },
    setShowShopModal: (store, action) => {
      return {
        ...store,
        showShopModal: action.payload,
      };
    },
    setShopItems: (store, action) => {
      return {
        ...store,
        shopItems: action.payload,
      };
    },
    setShowCallBackModal: (store, action) => {
      return {
        ...store,
        showCallBackModal: action.payload,
      };
    },
    setAfterSendMail: (store, action) => {
      return {
        ...store,
        afterSendMail: action.payload,
      };
    },
  },
});

export const {
  setOpenedProductPage,
  setShowShopModal,
  setShopItems,
  setShowCallBackModal,
  setAfterSendMail,
} = appSlice.actions;
export default appSlice.reducer;
