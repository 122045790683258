export const sendMail = async (body) => {
  console.log(JSON.stringify(body));
  let successfully;
  await fetch("api/sendMail", {
    //process.env.REACT_APP_API_URL +
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((e) => {
      console.log("fetch then", e);
      if (e.ok) {
        successfully = true;
      } else {
        successfully = false;
      }
      return e.json();
    })
    .then((e) => console.log("after json", e))
    .catch((err) => {
      console.log("fetch catch", err);
      successfully = false;
    });
  return successfully;
};
