import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import styles from "./style.module.css";
import Title from "../../components/Title";
import CallBack from "../../components/CallBack/CallBack";
import useScrollToTop from "../../helpers/useScrollToTop";

export default function Contacts() {
  useScrollToTop();
  return (
    <div>
      <Header />
      <div className={styles.main}>
        <Title text="Контакты" />
        <div className={styles.container}>
          <div className={styles.containerMain}>
            <div className={styles.firstRow}>
              <div className={styles.firstRowMain}>
                <div className={styles.textRow}>
                  <span className={styles.title}>Наш офис</span>
                  <span className={styles.description}>
                    Посёлок Фёдоровка, Дмитровский городской окру, Московская
                    область
                  </span>
                </div>
                <div className={styles.textRow}>
                  <span className={styles.title}>Наше производство</span>
                  <span className={styles.description}>
                    Посёлок Фёдоровка, Дмитровский городской окру, Московская
                    область
                  </span>
                </div>
                <div className={styles.textRow}>
                  <span className={styles.title}>Контакты</span>
                  <a
                    className={styles.description}
                    href="tel:89162335987"
                    style={{ margin: 0 }}
                  >
                    8 916 233 59 87
                  </a>
                  <a className={styles.description} href="tel:89850050506">
                    8 985 005 05 06
                  </a>
                </div>
                <div className={styles.textRow}>
                  <span className={styles.title}>Напишите нам</span>
                  <a
                    className={styles.description}
                    href="mailto:info@rosgoststroy.ru "
                  >
                    info@rosgoststroy.ru
                  </a>
                </div>
                <div className={styles.textRow}>
                  <span className={styles.title}>Рабочее время</span>
                  <span className={styles.description}>08:00 - 20:00</span>
                </div>
              </div>

              <iframe
                title="map"
                src="https://yandex.ru/map-widget/v1/?um=constructor%3A9943db11edd60ddc64de41e666cbac90fda059f14993657d8e9c4bf97d33747f&amp;source=constructor"
                style={{ width: "100%", height: "400px" }}
                frameBorder={0}
              ></iframe>
            </div>
          </div>
        </div>
        <CallBack />
      </div>
      <Footer />
    </div>
  );
}
