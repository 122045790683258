import React from "react";
import { ReactComponent as PlusSvg } from "./assets/plus.svg";
import { ReactComponent as MinusSvg } from "./assets/minus.svg";
import { ReactComponent as RemoveSvg } from "./assets/remove.svg";
import { useDispatch, useSelector } from "react-redux";
import { setShopItems } from "../../store/AppSlice";

export default function ModalItem({ item }) {
  const dispatch = useDispatch();
  const { shopItems } = useSelector(({ app }) => app);
  const filterItems = shopItems.filter((e) => e.title !== item.title);
  //   {title: 'Старый Город', image: '/static/media/stari-gord.cc69d288f1a4990276e5.png', price: '350', size: '200X200X100', color: 'Серый'}
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "70px",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <img
        src={item.image}
        alt={item.title}
        style={{
          height: window.innerWidth > 480 ? "100%" : "80%",
          width: "auto",
          borderRadius: "5px",
          marginRight: "5px",
        }}
      />
      <div style={{ width: "60%" }}>
        <p
          style={{
            fontFamily: "GothamPro",
            fontSize: "15px",
            lineHeight: "18px",
            margin: 0,
          }}
        >
          {item.color + " " + item.title}
        </p>
        <p
          style={{
            fontFamily: "GothamPro",
            fontSize: window.innerWidth > 480 ? "15px" : "14px",
            lineHeight: window.innerWidth > 480 ? "18px" : "16px",
            margin: 0,
          }}
        >
          {item.size}
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 15px",
        }}
      >
        <MinusSvg
          onClick={() => {
            if (item.count > 1) {
              dispatch(
                setShopItems(
                  shopItems.map((e) => {
                    if (e.title === item.title) {
                      return { ...e, count: e.count - 1 };
                    } else {
                      return e;
                    }
                  })
                )
              );
            } else {
              dispatch(setShopItems(filterItems));
            }
          }}
          style={{ width: "16px", height: "16px", border: 0 }}
        />
        <span
          style={{
            fontFamily: "GothamPro",
            fontSize: "16px",
            margin: "0 5px",
            position: "relative",
            bottom: "-2px",
          }}
        >
          {item.count}
        </span>
        <PlusSvg
          onClick={() => {
            dispatch(
              setShopItems(
                shopItems.map((e) => {
                  if (e.title === item.title) {
                    return { ...e, count: e.count + 1 };
                  } else {
                    return e;
                  }
                })
              )
            );
          }}
          style={{ width: "16px", height: "16px", border: 0 }}
        />
      </div>
      <div style={{ marginRight: "5px" }}>
        <span>{item.price}₽</span>
      </div>
      <RemoveSvg
        onClick={() => dispatch(setShopItems(filterItems))}
        style={{ width: "20px", height: "20px", border: 0 }}
      />
    </div>
  );
}
