import React from "react";

import LightGallery from "lightgallery/react";
import "./style.css";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// If you want you can use SCSS instead of css
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

export default function Gallery({ images }) {
  return (
    <LightGallery speed={500} plugins={[lgThumbnail, lgZoom]}>
      {images.map((img, i) => {
        return (
          <a href={img} key={i}>
            <img
              src={img}
              className="imgStyle"
              alt="Укладка тротуарной плитки"
            />
          </a>
        );
      })}
    </LightGallery>
  );
}
