import React from "react";
import styles from "./style.module.css";

export default function Title({ text, style = {} }) {
  return (
    <h2 className={styles.title} style={style}>
      {text}
    </h2>
  );
}
