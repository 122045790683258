import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAfterSendMail,
  setShopItems,
  setShowShopModal,
} from "../../store/AppSlice";
import ModalItem from "./ModalItem";
import styles from "./styles.module.css";
import { sendMail } from "../../api/api";

export default function Index() {
  const [name, setName] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");

  const { showShopModal, shopItems } = useSelector(({ app }) => app);
  const disptach = useDispatch();
  if (showShopModal && shopItems.length) {
    return (
      <div
        className={styles.container}
        onClick={(e) => {
          disptach(setShowShopModal(false));
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.main}
        >
          <p className={styles.title}>Ваш заказ:</p>
          <div className={styles.itemsContainer}>
            {shopItems?.map((item) => (
              <ModalItem item={item} />
            ))}
          </div>
          <div className={styles.amountContainer}>
            <span className={styles.priceDescription}>
              Цена учитывается за м2
            </span>
            <span className={styles.amount}>
              {shopItems?.reduce(
                (accumulator, currentValue) =>
                  accumulator + +(currentValue.price * currentValue.count),
                0
              )}
              ₽
            </span>
          </div>
          <input
            className={styles.modalInput}
            onChange={(e) => setName(e.target.value)}
            value={name}
            placeholder="Ваше имя"
          />
          <input
            className={styles.modalInput}
            onChange={(e) => setTel(e.target.value)}
            value={tel}
            placeholder="+7(999)999-9999"
          />
          <input
            className={styles.modalInput}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="Ваш Е-майл"
          />
          <div
            className={styles.modalBtn}
            onClick={() => {
              sendMail({ type: "order", name, email, tel, shopItems }).then(
                (successfully) => {
                  setName("");
                  setEmail("");
                  setTel("");
                  disptach(setShopItems([]));
                  disptach(
                    setAfterSendMail(successfully ? "successfully" : "failed")
                  );
                }
              );
            }}
          >
            <span>Заказать</span>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
