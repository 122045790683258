import React from "react";
import styles from "./style.module.css";
import Title from "../../../components/Title";
import { Carousel } from "react-responsive-carousel";
import SlideItem from "./components/SlideItem";
import slideData from "./slideData";
import { ReactComponent as ArrowSvg } from "../../../assets/Arrow.svg";

export default function Index() {
  return (
    <div className={styles.container}>
      <Title text={"ПОСМОТРИТЕ ВСЕ САМОЕ ЛУЧШЕЕ В ОДНОМ МЕСТЕ"} />
      <p className={styles.subTitle}>Что мы предлагаем?</p>
      <div className={styles.main}>
        <Carousel
          showArrows={true}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          topOnHover={false}
          width={window.innerWidth}
          transitionTime={2000}
          swipeable={window.innerWidth > 768}
          renderArrowNext={(onClick, hasNext) => {
            if (hasNext)
              return (
                <div onClick={onClick} className={styles.arrowBtn}>
                  <ArrowSvg />
                </div>
              );
          }}
          renderArrowPrev={(onClick, hasPrev) => {
            if (hasPrev)
              return (
                <div
                  onClick={onClick}
                  className={styles.arrowBtn}
                  style={{ transform: "rotate(180deg)", left: "10px" }}
                >
                  <ArrowSvg />
                </div>
              );
          }}
        >
          {slideData.map(
            ({ image, title, position, description, link }, index) => (
              <SlideItem
                key={index}
                image={image}
                title={title}
                position={position}
                description={description}
                onClickMore={link}
              />
            )
          )}
        </Carousel>
      </div>
    </div>
  );
}
