import React from "react";
import styles from "./style.module.css";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Title from "../../components/Title";
import CatalogCard from "../../components/CatalogCard";
import useScrollToTop from "../../helpers/useScrollToTop";
const _catalogData = [
  {
    img: require("./assets/vibrolitya.png"),
    title: "Вибролитьевая плитка",
    navigateTo: "vibrolitya",
  },

  {
    img: require("./assets/vibropress.webp"),
    title: "Вибропрессованная плитка",
    navigateTo: "vibropress",
  },
  {
    img: require("./assets/bordyuri.jpg"),
    title: "Бордюры",
    navigateTo: "bordyuri",
  },
  {
    img: require("./assets/vodostoki.jpg"),
    title: "Водостоки",
    navigateTo: "vadastoki",
  },
  {
    img: require("./assets/kalpaki.jpg"),
    title: "Крышки для заборов",
    navigateTo: "krishi",
  },
  {
    img: require("./assets/latok.jpeg"),
    title: "Лоток водоотводный",
    navigateTo: "latok",
  },
];
export default function Catalog() {
  useScrollToTop();
  return (
    <div>
      <Header />
      <div className={styles.main}>
        <Title text="Каталог" />
        <div className={styles.catalogContainer}>
          {_catalogData.map((item, index) => (
            <CatalogCard
              key={index}
              image={item.img}
              title={item.title}
              navigateTo={item.navigateTo}
            />
          ))}
        </div>

        <Footer />
      </div>
    </div>
  );
}
