import React from "react";
import styles from "./style.module.css";
import { Link } from "react-router-dom";

export default function CatalogCard({ image, title, navigateTo }) {
  return (
    <Link
      className={styles.card}
      to={navigateTo}
      style={{ backgroundImage: `url(${image})` }}
    >
      <p className={styles.title}>{title}</p>
    </Link>
  );
}
