import { _colors } from "../../constants";
import { ReactComponent as ArrowSvg } from "../../assets/Arrow.svg";

export default function Button({
  onClick = () => {},
  text = "text",
  fill = false,
  style = {},
  textStyle = {},
  arrow,
  href = "",
}) {
  return (
    <a
      href={href}
      onClick={onClick}
      style={{
        display: "flex",
        background: fill ? _colors.BORDO : "transparent",
        padding: window.innerWidth > 768 ? "20px 40px" : "15px 20px",
        marginTop: window.innerWidth > 768 ? "40px" : "20px",
        border: `1px solid ${_colors.BORDO}`,
        cursor: "pointer",
        alignSelf: "flex-start",
        textDecoration: "none",
        ...style,
      }}
    >
      <span
        style={{
          fontFamily: "GothamPro",
          fontWeight: 700,
          fontSize: window.innerWidth > 768 ? "18px" : "15px",
          lineHeight: "17px",
          letterSpacing: "-0.05em",
          color: fill ? _colors.WHITE : _colors.BORDO,
          ...textStyle,
          marginRight: arrow ? "10px" : 0,
        }}
      >
        {text}
      </span>
      {arrow ? (
        <ArrowSvg width={window.innerWidth > 768 ? "23px" : "18px"} />
      ) : null}
    </a>
  );
}
