export default [
  {
    position: "left",
    image: require("./assets/1.jpg"),
    title: "Укладка тротуарной плитки",
    description:
      "Обратившись в «РосГостСтрой» за помощью, вы получите грамотную консультацию, а также полезные идеи для оформления вашего участка: как сада у загородного дома, так и площадки перед кафе или гостиницей...",
    link: "/services/ukladka",
  },
  {
    position: "right",
    image: require("./assets/2.jpg"),
    title: "Художественный ковка",
    description:
      "Компания «РосГостСтрой» изготавливает эксклюзивные кованые изделия из металла по индивидуальному заказу. Каждая работа наших кузнецов превращается практически в произведение искусства, каждый предмет индивидуален! И любой заказчик может спокойно похвастаться, что он единственный обладатель данного кованного произведения...",
    link: "/services/kovka",
  },
  {
    position: "left",
    image: require("./assets/3.jpg"),
    title: "Доставка материалов",
    description:
      "Независимо от того , будете ли Вы заказывать у нас товар того или иного вида , у нас действует гибкая система доставок по всей Московской области. Мы имеем большой парк грузоперевозочной и погрузочной техники которая доставит ваш товар в заданные сроки, гарантируя их сохранность и целостность... ",
    link: "/services/dostavka",
  },
  {
    position: "right",
    image: require("./assets/4.jpg"),
    title: "Лоток водоотводный",
    description:
      "Дрена́ж (от франц. Drainage) это удаление подземных (грунтовых) либо поверхностных вод с земельных участков и от фундаментов зданий или сооружений. удаление воды с поверхности земли либо подземных вод...",
    link: "/services/drenaj",
  },
  {
    position: "left",
    image: require("./assets/5.jpg"),
    title: "Благоустройство территорий",
    description:
      "Благоустройство территории - комплекс мероприятий по инженерной подготовке и обеспечению безопасности, озеленению, устройству покрытий, освещению, размещению малых архитектурных форм и объектов монументального искусства...",
    link: "/services/blagoustroistva",
  },
];
